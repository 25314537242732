// stagingReportTab.js

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const StagingReportTab = () => {
  const stagingData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          sourceInstanceName: { eq: "cucumberReports" }
          name: { regex: "/STAGING/" }
        }
        sort: { fields: birthTime, order: DESC }
        limit: 20
      ) {
        edges {
          node {
            publicURL
            name
            birthTime
          }
        }
      }
    }
  `)
  return (
    <div className="StagingReportTab">
      <ul>
        {stagingData.allFile.edges.map((file, index) => {
          return (
            <li id={`report-${index}`}>
              <a
                href={file.node.publicURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.node.name}
              </a>{" "}
              <br></br>Creation DateTime: {file.node.birthTime}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default StagingReportTab
