import React, { useState } from "react"
import StagingReportTab from "../allTabs/stagingReportTab"
import ProductionReportTab from "../allTabs/ProductionReportTab"

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("ProductionReportTab")
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("StagingReportTab")
  }
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("ProductionReportTab")
  }

  return (
    <div className="Tabs">
      {/* Tab nav */}
      <ul className="nav">
        <button
          className={activeTab === "StagingReportTab" ? "active" : ""}
          onClick={handleTab1}
          onKeyDown={handleTab1}
        >
          Staging Env Reports
        </button>
        <button
          className={activeTab === "ProductionReportTab" ? "active" : ""}
          onClick={handleTab2}
          onKeyDown={handleTab2}
        >
          Production Env Reports
        </button>
      </ul>
      <div className="outlet">
        {activeTab === "ProductionReportTab" ? (
          <ProductionReportTab />
        ) : (
          <StagingReportTab />
        )}
      </div>
    </div>
  )
}
export default Tabs
