import React from "react"

import Layout from "../components/layout"
import Tabs from "../components/tabComponents/tabs"

const ReportsPage = () => {
  return (
    <Layout>
      <h1>All Cucumber Test Reports</h1>
      <Tabs />
    </Layout>
  )
}
export default ReportsPage
